// Constants.js
const prod = {
  url: {
    API_URL: "https://listener.cosalux.net/client"
  }
};
const dev = {
  url: {
    API_URL: "http://192.168.2.135:3001/client"
  }
};
export const config = process.env.NODE_ENV === "development" ? dev : prod;
