import React, { Component, Fragment } from "react";
import "./App.css";
import Impressum from "./Impressum";
import openSocket from "socket.io-client";
import Div100vh from "react-div-100vh";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Howl } from "howler";
import { config } from "./Constants";

const socket = openSocket(config.url.API_URL, {
  reconnection: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  timeout: 15000
});

const HOST_AVAIL = "server_available";
const CLIENT_CONNECT = "iwannaplay";
const NEW_VALUE = "newValue";
const PLAYER_READY = "player_ready";
const BALL_LOST = "lost_ball";

const WAITINGLIST = "waitinglist";
const CLIENT_GET_READY = "get_ready";
const CLIENT_GAME_ENDED = "game_ended";

const PLAY_SOUND = "playsound";

const createSound = () => {
  return new Howl({
    src: ["sound.mp3"],
    sprite: {
      silence: [0, 300],
      brick: [1000, 500],
      paddle: [2000, 100]
    }
  });
};

const GS = {
  looking_for_host: 1,
  not_running: 2,
  error: 3,
  show_info: 4,
  waiting_list: 8,
  playing: 16,
  won: 17,
  lost: 18
};

const sounds = ["silence", "brick", "paddle"];
function sckt(callback) {
  [
    HOST_AVAIL,
    CLIENT_GET_READY,
    CLIENT_GAME_ENDED,
    BALL_LOST,
    WAITINGLIST,
    "disconnect",
    "connect_timeout",
    "connect_error",
    PLAY_SOUND
  ].map(x => socket.on(x, msg => callback(x, msg)));

  return {
    e: (ev, msg) => socket.emit(ev, msg)
  };
}

class App extends Component {
  constructor() {
    super();
    this.state = {
      game: GS.looking_for_host,
      impressum: false,
      waiting_list: 0,
      reason: null
    };
    this.slider = null;
    this.slideToStart = React.createRef();
    this.socket = sckt(this.myCallback);
    this.sendReady = false;
    this.sound = null;
    this.timeReady = true;
    console.log(this.socket);
  }

  // set slider to 0.5
  // () =>  this.slider ? (this.slider.value = 0.5) : null
  myCallback = (event, message) => {
    if (event === HOST_AVAIL) {
      const game = this.state.game;

      console.log(HOST_AVAIL, game, GS.looking_for_host, message === true);
      if (
        (game === GS.not_running || game === GS.looking_for_host) &&
        message === true
      ) {
        console.log("show info");
        this.setState({ game: GS.show_info });
      } else if (game >= GS.looking_for_host && message === false) {
        this.setState({ game: GS.not_running });
      }
    } else if (event === CLIENT_GET_READY) {
      if (this.state.game === GS.waiting_list) {
        this.setState({ game: GS.playing }, () => {
          if (this.slider !== null) this.slider.value = 0.5;
        });
      }
    } else if (event === CLIENT_GAME_ENDED) {
      if (this.state.game === GS.playing) {
        if (message === false) {
          this.setState({ game: GS.lost });
        } else this.setState({ game: GS.won });
      }
    } else if (event === BALL_LOST) {
      if (this.state.game === GS.playing) {
        this.sendReady = false;
        this.timeReady = false;
        setTimeout(() => this.timeReady = true, 1000);
        if (this.slideToStart.current !== null) {
          this.slideToStart.current.checked = false;
        }
        if (this.slider !== null) this.slider.value = 0.5;
      }
    } else if (event === WAITINGLIST) {
      if (this.state.game === GS.waiting_list)
        this.setState({ waiting_list: parseInt(message) + 1 });
      else
        this.setState({
          waiting_list: parseInt(message) + 1,
          game: GS.waiting_list
        });
    } else if (event === "disconnect") {
      this.setState({ game: GS.error });
    } else if (event === "connect_timeout") {
      this.setState({ game: GS.not_running });
    } else if (event === PLAY_SOUND) {
      const i = parseInt(message);
      if (i > 0 && i < sounds.length && this.sound !== null)
        this.sound.play(sounds[i]);
    }
  };

  showSlider() {
    return (
      <input
        type="range"
        key="start"
        name="volume"
        step="0.01"
        min="0"
        max="1"
        onChange={e => {
          if (this.timeReady) {
            if (this.sendReady === false) {
              this.sendReady = true;
              console.log("slideToStart", this.slideToStart);
              if (this.slideToStart.current !== null)
                this.slideToStart.current.checked = true;
              this.socket.e(PLAYER_READY, null);
            } else {
              this.socket.e(NEW_VALUE, e.target.value);
            }
          }
        }}
        ref={r => (this.slider = r)}
      />
    );
  }

  showNotRunning() {
    return (
      <Fragment>
        <div className="box lostred image sad">Sorry, unsere Server sind offline</div>
        <div className="text small end">Versuchs doch später nochmal!</div>
      </Fragment>
    );
  }

  showPlayButton(text = "play", color = "play") {
    return (
      <button
        className={color}
        onClick={() =>
          this.setState({ game: GS.waiting_list }, () => {
            if (this.sound === null) this.sound = createSound();
            this.sound.play("silence");
            this.socket.e(CLIENT_CONNECT, null);
          })
        }
      >
        {text}
      </button>
    );
  }

  showError() {
    return <Fragment>
      <div className="box lostred image sad">Sorry, da ist was schief gelaufen</div>
      <div className="text small end">Versuchs doch nochmal!</div>
    </Fragment>;
  }

  showWaitinglist() {
    const waitinglist = this.state.waiting_list;
    return (
      <React.Fragment>
        <div className="box yellow">DU BIST IN DER WARTE&shy;SCHLANGE</div>
        <div className="text big text-center waiting">
          <div className="place">PLATZ</div>
          <div>{waitinglist}</div>
        </div>
      </React.Fragment>
    );
  }

  getLost() {
    return (
      <Fragment>
        <div className="box lostred image sad">DAS HAT LEIDER NICHT GEKLAPPT</div>
        <div className="text small end">Versuchs doch nochmal!</div>
      </Fragment>
    );
  }

  showLoading() {
    return <Fragment>
      <div className="box red image ball">HEY! WIR LADEN DEIN SPIEL</div>
      <div className="text big">BITTE WARTEN...</div>
    </Fragment>
  }

  showInfo() {
    return <Fragment>
      <div className="box pink image gift">PLAY & WIN! GUTSCHEIN LSTNR</div>
      <div className="text small">Bewege über den Slider auf deinem Mobile Screen den Balken auf dem Schaufenster, sodass der Ball daran abprallt und sprenge die Blöcke.</div>
    </Fragment>
  }

  showPlaying() {
    return (
      <Fragment>
        <div className="box orange o30">SCHAU AUF´S FENSTER</div>
        <div className="text big end"><input type="checkbox" ref={this.slideToStart}></input><label>SLIDE TO START</label></div>
      </Fragment>
    );
  }

  showWon() {
    return (
      <Fragment>
        <div className="box green image gifto">WOW! DU HAST GEWONNEN</div>
        <div className="icontainer text">
          <iframe title="chimp" src="/mailchimp.html" onLoad="alert(this.contentWindow.location);" style={{ "border": "0px", "outline": "0px", "width": "100%", "gridRow": "box / text", "grid-row": "text" }} />
        </div>
      </Fragment>);
  }

  sendButton() {
    return (
      <a className="button" href="/GUTSCHEIN.pdf" download>save</a>);
  }

  getPage() {
    switch (this.state.game) {
      case GS.looking_for_host:
        return [this.showLoading(), null, "s" + this.state.game];
      case GS.not_running:
        return [this.showNotRunning(), null, "s" + this.state.game];
      case GS.playing:
        return [this.showPlaying(), this.showSlider(), "s" + this.state.game];
      case GS.show_info:
        return [
          this.showInfo(),
          <Fragment>{this.showPlayButton()}<a href="/#" onClick={() => this.setState({ impressum: true })} className="imprint">Imprint</a> </Fragment>,
          "s" + this.state.game
        ];
      case GS.won:
        return [this.showWon(), null, "s" + this.state.game];
      case GS.lost:
        return [
          this.getLost(),
          this.showPlayButton("try again", "lostred"),
          "s" + this.state.game
        ];
      case GS.waiting_list:
        return [
          this.showWaitinglist(),
          <button className="yellow" onClick={() => window.location.reload()}>
            cancel
          </button>,
          "s" + this.state.game
        ];
      default:
        return [
          this.showError(),
          <button className="lostred" onClick={() => window.location.reload()}>TRY AGAIN</button>,
          "e" + this.state.game
        ];
    }
  }

  backButton() {
    return <button className="impressum" onClick={() => this.setState({ impressum: false })}>BACK</button>;
  }

  pageImpressum() {
    const button = <a href="/#" style={{ "paddingTop": "10px", "color": "#000" }} className="button impressum" onClick={() => this.setState({ impressum: false })}>BACK</a>; //this.backButton();
    return [
      { id: "top", cl: "top", p: null },
      { id: "mimpressum", cl: "main", p: <Fragment><Impressum button={button} /> </Fragment> },
      { id: "bimpressum", cl: "bottom", p: this.backButton() }
    ]
  }
  y
  renderPage() {

    const impressum = this.state.impressum;

    if (!impressum) {
      const page = this.getPage();

      return [
        { id: "top", cl: "top", p: null },
        { id: "m" + page[2], cl: "main", p: page[0] },
        { id: "b" + page[2], cl: "bottom", p: page[1] }
      ];
    } else {
      return this.pageImpressum();
    }
  }

  render() {
    const a = this.renderPage();
    console.log(this.state.game, a);
    const isWon = this.state.game === GS.won;

    const main = "App" + (isWon ? " won" : "");
    //<Impressum />;
    return (
      <Div100vh className="">
        <TransitionGroup className={main}>
          {a.map(({ id, cl, p }) => (
            <CSSTransition key={id} timeout={500} classNames="item">
              <div className={cl}>{p}</div>
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Div100vh>
    );
  }
}
export default App;
