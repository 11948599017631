import React, { Fragment } from "react";
class Impressum extends React.Component {
  render() {
    return (
      <Fragment>
        <div className="box impressum">Impressum / Datenschutz</div>
        <div className="text small scroll">{this.props.button}<h3>Impressum</h3><h4 >Angaben gemäß § 5 TMG:</h4>
          <div>The Listener GmbH<br />
            Stephanstraße 3<br />
            60313 Frankfurt am Main</div>
          <h4>Vertreten durch:</h4>
          <div>Hakan Temür, Geschäftsführer</div>
          <h4>Kontakt:</h4>
          <div>Telefon: 069 2100 8000<br />
            E-Mail: contact@thelistener.de</div>
          <h4>Registereintrag:</h4>
          <div>Eintragung im Handelsregister.<br />
            Registergericht: Amtsgericht Frankfurt am Main<br />
            Registernummer: HRB 95974</div>
          <h4>Umsatzsteuer:</h4>
          <div>Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:<br />
            DE287891234</div>
          <h4>Streitschlichtung</h4>
          <div>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:&nbsp;<a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.<br />
            Unsere E-Mail-Adresse finden Sie oben im Impressum.</div>
          <div>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.</div>
          <h4>Haftung für Inhalte</h4>
          <div>Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.</div>
          <div>Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.</div>
          <h4>Haftung für Links</h4>
          <div>Unser Angebot enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.</div>
          <div>Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.</div>
          <h4>Urheberrecht</h4>
          <div>Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.</div>
          <div>Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.</div>
          <h3>Site Notice</h3>
          <h4>Information provided according to Sec. 5 German Telemedia Act (TMG):</h4>
          <div>The Listener GmbH<br />
            Stephanstraße 3<br />
            60313 Frankfurt am Main</div>
          <h4>Represented by:</h4>
          <div>Hakan Temür, Geschäftsführer</div>
          <h4>Contact:</h4>
          <div>Telephone: 069 2100 8000<br />
            Email: contact@thelistener.de</div>
          <h4>Register entry:</h4>
          <div>Entry in the Handelsregister.<br />
            Registering court: Amtsgericht Frankfurt am Main<br />
            Registration number: HRB 95974</div>
          <h4>VAT:</h4>
          <div>VAT Id number according to Sec. 27 a German Value Added Tax Act:<br />
            DE287891234</div>
          <h4>Dispute resolution</h4>
          <div>The European Commission provides a platform for online dispute resolution (OS):&nbsp;<a href="https://ec.europa.eu/consumers/odr">https://ec.europa.eu/consumers/odr</a>.<br />
            Please find our email in the impressum/legal notice.</div>
          <div>We do not take part in online dispute resolutions at consumer arbitration boards.</div>
          <h4>Liability for Contents</h4>
          <div>As service providers, we are liable for own contents of these websites according to Sec. 7, paragraph 1 German Telemedia Act (TMG). However, according to Sec. 8 to 10 German Telemedia Act (TMG), service providers are not obligated to permanently monitor submitted or stored information or to search for evidences that indicate illegal activities.</div>
          <div>Legal obligations to removing information or to blocking the use of information remain unchallenged. In this case, liability is only possible at the time of knowledge about a specific violation of law. Illegal contents will be removed immediately at the time we get knowledge of them.</div>
          <h4>Liability for Links</h4>
          <div>Our offer includes links to external third party websites. We have no influence on the contents of those websites, therefore we cannot guarantee for those contents. Providers or administrators of linked websites are always responsible for their own contents.</div>
          <div>The linked websites had been checked for possible violations of law at the time of the establishment of the link. Illegal contents were not detected at the time of the linking. A permanent monitoring of the contents of linked websites cannot be imposed without reasonable indications that there has been a violation of law. Illegal links will be removed immediately at the time we get knowledge of them.</div>
          <h4>Copyright</h4>
          <div>Contents and compilations published on these websites by the providers are subject to German copyright laws. Reproduction, editing, distribution as well as the use of any kind outside the scope of the copyright law require a written permission of the author or originator. Downloads and copies of these websites are permitted for private use only.<br />
            The commercial use of our contents without permission of the originator is prohibited.</div>
          <div>Copyright laws of third parties are respected as long as the contents on these websites do not originate from the provider. Contributions of third parties on this site are indicated as such. However, if you notice any violations of copyright law, please inform us. Such contents will be removed immediately.</div>
          <h3>Datenschutzerklärung</h3>
          <h4>1. Datenschutz auf einen Blick</h4>
          <h4>Allgemeine Hinweise</h4>
          <div>Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie unsere Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.</div>
          <h4>Datenerfassung auf unserer Website</h4>
          <div><strong>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</strong></div>
          <div>Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Impressum dieser Website entnehmen.</div>
          <div><strong>Wie erfassen wir Ihre Daten?</strong></div>
          <div>Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z.B. um Daten handeln, die Sie in ein Kontaktformular eingeben.</div>
          <div>Andere Daten werden automatisch beim Besuch der Website durch unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z.B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie unsere Website betreten.</div>
          <div><strong>Wofür nutzen wir Ihre Daten?</strong></div>
          <div>Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.</div>
          <div><strong>Welche Rechte haben Sie bezüglich Ihrer Daten?</strong></div>
          <div>Sie haben jederzeit das Recht unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung, Sperrung oder Löschung dieser Daten zu verlangen. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.</div>
          <h4>2. Allgemeine Hinweise und Pflichtinformationen</h4>
          <h4>Datenschutz</h4>
          <div>Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</div>
          <div>Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.</div>
          <div>Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.</div>
          <h4>Hinweis zur verantwortlichen Stelle</h4>
          <div>Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:</div>
          <div>The Listener Frankfurt<br />
            Stephanstraße 3<br />
            60313 Frankfurt am Main</div>
          <div>Telefon: 069 2100 8000<br />
            E-Mail: contact@thelistener.de</div>
          <div>Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.</div>
          <h4>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h4>
          <div>Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Dazu reicht eine formlose Mitteilung per E-Mail an uns. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.</div>
          <h4>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h4>
          <div>Im Falle datenschutzrechtlicher Verstöße steht dem Betroffenen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Zuständige Aufsichtsbehörde in datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte des Bundeslandes, in dem unser Unternehmen seinen Sitz hat. Eine Liste der Datenschutzbeauftragten sowie deren Kontaktdaten können folgendem Link entnommen werden:&nbsp;<a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</div>
          <h4>Recht auf Datenübertragbarkeit</h4>
          <div>Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.</div>
          <h4>Auskunft, Sperrung, Löschung</h4>
          <div>Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung, Sperrung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit unter der im Impressum angegebenen Adresse an uns wenden.</div>
          <h4>3. Datenerfassung auf unserer Website</h4>
          <h4>Server-Log-Dateien</h4>
          <div>Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-Log-Dateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:</div>
          <ul >
            <li>Browsertyp und Browserversion</li>
            <li>verwendetes Betriebssystem</li>
            <li>Referrer URL</li>
            <li>Hostname des zugreifenden Rechners</li>
            <li>Uhrzeit der Serveranfrage</li>
            <li>IP-Adresse</li>
          </ul>
          <div>Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.</div>
          <div>Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. f DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.</div>
          <h4>4. Plugins und Tools</h4>
          <h4>Google Web Fonts</h4>
          <div>Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so genannte Web Fonts, die von Google bereitgestellt werden. Beim Aufruf einer Seite lädt Ihr Browser die benötigten Web Fonts in ihren Browsercache, um Texte und Schriftarten korrekt anzuzeigen.</div>
          <div>Zu diesem Zweck muss der von Ihnen verwendete Browser Verbindung zu den Servern von Google aufnehmen. Hierdurch erlangt Google Kenntnis darüber, dass über Ihre IP-Adresse unsere Website aufgerufen wurde. Die Nutzung von Google Web Fonts erfolgt im Interesse einer einheitlichen und ansprechenden Darstellung unserer Online-Angebote. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</div>
          <div>Wenn Ihr Browser Web Fonts nicht unterstützt, wird eine Standardschrift von Ihrem Computer genutzt.</div>
          <div>Weitere Informationen zu Google Web Fonts finden Sie unter&nbsp;<a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>&nbsp;und in der Datenschutzerklärung von Google:&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</div>
          <h4>Google Maps</h4>
          <div>Diese Seite nutzt über eine API den Kartendienst Google Maps. Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</div>
          <div>Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP Adresse zu speichern. Diese Informationen werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser Seite hat keinen Einfluss auf diese Datenübertragung.</div>
          <div>Die Nutzung von Google Maps erfolgt im Interesse einer ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns auf der Website angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.</div>
          <div>Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in der Datenschutzerklärung von Google:&nbsp;<a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.</div>
          <h4>Privacy Policy</h4>
          <h4>1. An overview of data protection</h4>
          <h4>General</h4>
          <div>The following gives a simple overview of what happens to your personal information when you visit our website. Personal information is any data with which you could be personally identified. Detailed information on the subject of data protection can be found in our privacy policy found below.</div>
          <h4>Data collection on our website</h4>
          <div><strong>Who is responsible for the data collection on this website?</strong></div>
          <div>The data collected on this website are processed by the website operator. The operator’s contact details can be found in the website’s required legal notice.</div>
          <div><strong>How do we collect your data?</strong></div>
          <div>Some data are collected when you provide it to us. This could, for example, be data you enter on a contact form.</div>
          <div>Other data are collected automatically by our IT systems when you visit the website. These data are primarily technical data such as the browser and operating system you are using or when you accessed the page. These data are collected automatically as soon as you enter our website.</div>
          <div><strong>What do we use your data for?</strong></div>
          <div>Part of the data is collected to ensure the proper functioning of the website. Other data can be used to analyze how visitors use the site.</div>
          <div><strong>What rights do you have regarding your data?</strong></div>
          <div>You always have the right to request information about your stored data, its origin, its recipients, and the purpose of its collection at no charge. You also have the right to request that it be corrected, blocked, or deleted. You can contact us at any time using the address given in the legal notice if you have further questions about the issue of privacy and data protection. You may also, of course, file a complaint with the competent regulatory authorities.</div>
          <h4>2. General information and mandatory information</h4>
          <h4>Data protection</h4>
          <div>The operators of this website take the protection of your personal data very seriously. We treat your personal data as confidential and in accordance with the statutory data protection regulations and this privacy policy.</div>
          <div>If you use this website, various pieces of personal data will be collected. Personal information is any data with which you could be personally identified. This privacy policy explains what information we collect and what we use it for. It also explains how and for what purpose this happens.</div>
          <div>Please note that data transmitted via the internet (e.g. via email communication) may be subject to security breaches. Complete protection of your data from third-party access is not possible.</div>
          <h4>Notice concerning the party responsible for this website</h4>
          <div>The party responsible for processing data on this website is:</div>
          <div>The Listener Frankfurt<br />
            Stephanstraße 3<br />
            60313 Frankfurt am Main</div>
          <div>Telephone: 069 2100 8000<br />
            Email: contact@thelistener.de</div>
          <div>The responsible party is the natural or legal person who alone or jointly with others decides on the purposes and means of processing personal data (names, email addresses, etc.).</div>
          <h4>Revocation of your consent to the processing of your data</h4>
          <div>Many data processing operations are only possible with your express consent. You may revoke your consent at any time with future effect. An informal email making this request is sufficient. The data processed before we receive your request may still be legally processed.</div>
          <h4>Right to file complaints with regulatory authorities</h4>
          <div>If there has been a breach of data protection legislation, the person affected may file a complaint with the competent regulatory authorities. The competent regulatory authority for matters related to data protection legislation is the data protection officer of the German state in which our company is headquartered. A list of data protection officers and their contact details can be found at the following link:&nbsp;<a href="https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html">https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html</a>.</div>
          <h4>Right to data portability</h4>
          <div>You have the right to have data which we process based on your consent or in fulfillment of a contract automatically delivered to yourself or to a third party in a standard, machine-readable format. If you require the direct transfer of data to another responsible party, this will only be done to the extent technically feasible.</div>
          <h4>Information, blocking, deletion</h4>
          <div>As permitted by law, you have the right to be provided at any time with information free of charge about any of your personal data that is stored as well as its origin, the recipient and the purpose for which it has been processed. You also have the right to have this data corrected, blocked or deleted. You can contact us at any time using the address given in our legal notice if you have further questions on the topic of personal data.</div>
          <h4>3. Data collection on our website</h4>
          <h4>Server log files</h4>
          <div>The website provider automatically collects and stores information that your browser automatically transmits to us in “server log files”. These are:</div>
          <ul>
            <li>Browser type and browser version</li>
            <li>Operating system used</li>
            <li>Referrer URL</li>
            <li>Host name of the accessing computer</li>
            <li>Time of the server request</li>
            <li>IP address</li>
          </ul>
          <div>These data will not be combined with data from other sources.</div>
          <div>The basis for data processing is Art. 6 (1) (f) DSGVO, which allows the processing of data to fulfill a contract or for measures preliminary to a contract.</div>
          <h4>4. Plugins and tools</h4>
          <h4>Google Web Fonts</h4>
          <div>For uniform representation of fonts, this page uses web fonts provided by Google. When you open a page, your browser loads the required web fonts into your browser cache to display texts and fonts correctly.</div>
          <div>For this purpose your browser has to establish a direct connection to Google servers. Google thus becomes aware that our web page was accessed via your IP address. The use of Google Web fonts is done in the interest of a uniform and attractive presentation of our website. This constitutes a justified interest pursuant to Art. 6 (1) (f) DSGVO.</div>
          <div>If your browser does not support web fonts, a standard font is used by your computer.</div>
          <div>Further information about handling user data, can be found at&nbsp;<a href="https://developers.google.com/fonts/faq">https://developers.google.com/fonts/faq</a>&nbsp;and in Google’s privacy policy at&nbsp;<a href="https://www.google.com/policies/privacy/">https://www.google.com/policies/privacy/</a>.</div>
          <h4>Google Maps</h4>
          <div>This site uses the Google Maps map service via an API. It is operated by Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA.</div>
          <div>To use Google Maps, it is necessary to save your IP address. This information is generally transmitted to a Google server in the USA and stored there. The provider of this site has no influence on this data transfer.</div>
          <div>The use of Google Maps is in the interest of making our website appealing and to facilitate the location of places specified by us on the website. This constitutes a justified interest pursuant to Art. 6 (1) (f) DSGVO.</div>
          <div>Further information about handling user data, can be found in the data protection declaration of Google at&nbsp;<a href="https://www.google.de/intl/de/policies/privacy/">https://www.google.de/intl/de/policies/privacy/</a>.</div></div>
      </Fragment>
    );
  }
}
export default Impressum;
